<template>
    <b-container>
        <b-row>
            <b-col>
                <amplify-authenticator :initial-auth-state="authMode" username-alias="email">
                    <amplify-sign-up
                        slot="sign-up"
                        header-text="First Five SC - Create an Account"
                        submit-button-text="Register"
                        username-alias="email"
                    >
                        <div slot="header-subtitle">
                           <p>Your password must contain an uppercase letter, lowercase letter, number and symbol (such as *, $, %, etc). </p>
                           <p>We will text a confirmation code to the phone number you enter below.</p>
                        </div>
                    </amplify-sign-up>
                    <amplify-sign-in slot="sign-in"
                    username-alias="email">

                    </amplify-sign-in>
                </amplify-authenticator>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { Hub, API, Amplify } from 'aws-amplify';
import * as mutations from '../graphql/mutations.js';
import {
  listUsers,
  listChildren,

} from "../graphql/queries";
import {AuthState} from '@aws-amplify/ui-components';

export default{
    name: 'SignUp',
    props: ['data'],
    data() {
        return {
           
            authMode: AuthState.SignUp,
            completedForms: [],
            inprogressForms: [],
            currentUser: null,
            children: []
        }
    },
    created() {
          Amplify.Auth.currentAuthenticatedUser()
      .then((result) => {
        if (result) {
            this.$router.push({name: 'AuthorizedApply'});
        }
      })
      .catch((err) => console.log("no user", err));
        Hub.listen('auth', async (data) => {
            console.log('auth: ', data.payload.event)
            switch (data.payload.event) {
                case 'signIn':
                    console.log('user signed in');

                    //if user and children data update user data
                 
                  
                        if(this.$session.get('currentUser')){
                            this.currentUser = this.$session.get('currentUser');

                                //delete existing user info
                              var userInfos = await API.graphql({query: listUsers, authMode: "AMAZON_COGNITO_USER_POOLS",});
                                for(var m = 0; m < userInfos.data.listUsers.items.length; m++) {
                                    await API.graphql({ query: mutations.deleteUser, variables: {input: {id: userInfos.data.listUsers.items[m].id }}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
                                }

                            //add user info
                            await API.graphql({ query: mutations.createUser, variables: {input: this.currentUser}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
                            this.$session.remove('currentUser');
                        }
                       
                        if(this.$session.get('children')){
                            this.children = this.$session.get('children');
                            if (this.children.length > 0) {

                                //delete existing children
                                var children = await API.graphql({ query: listChildren, authMode: "AMAZON_COGNITO_USER_POOLS",});
                                for(var l = 0; l < children.data.listChildren.items.length; l++) {
                                    await API.graphql({ query: mutations.deleteChild, variables: {input: {id: children.data.listChildren.items[l].id }}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
                                }

                                //add children from session
                                for(var i = 0; i < this.children.length; i++){
                                    var child = this.children[i];
                                    await API.graphql({ query: mutations.createChild, variables: {input: child}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
                                }
                                this.$session.remove('children');
                            }
                            else {
                                this.$router.push({name: 'Start'});
                            }
                            
                        } 


                    //if inprogress and completed add to user
                    console.log('importing session forms to user - singup');
                        if(this.$session.get('completedForms')){
                            this.completedForms = this.$session.get('completedForms');
                            for(var k = 0; k < this.completedForms.length; k++) {
                                await API.graphql({ query: mutations.createCompletedForm, variables: {input: this.completedForms[k]}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
                            }
                            this.$session.remove('completedForms');
                        }
                        if(this.$session.get('inprogressForms')){
                            this.inprogressForms = this.$session.get('inprogressForms');
                             for(var j = 0; j < this.inprogressForms.length; j++) {
                                await API.graphql({ query: mutations.createInProgressForm, variables: {input: this.inprogressForms[j]}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
                            }
                             this.$session.remove('inprogressForms');
                        }



                    this.$router.push({name: 'AuthorizedApply'});
                    break;
            }
        });
    },
    computed: {

    },
    methods: {
        
    }
}
</script>