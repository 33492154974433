<template>
    <b-container class="mt-5">
        <div class="form-container">
            <h2>Basic Information</h2>
            <p>Let's start with some basic information that will be used on your application.</p>
            <b-row v-if="showForm">
                <b-col>
                    <b-form @submit="basicInfoSubmit">
                        <b-row>
                            <b-col cols="12" md="3" class="my-2">
                                <b-input type="text" placeholder="First Name" v-model="basicInformation.firstName"></b-input>
                            </b-col>
                            <b-col cols="12" md="3" class="my-2">
                                <b-input type="text" placeholder="Middle Name" v-model="basicInformation.middleName"></b-input>
                            </b-col>
                            <b-col cols="12" md="3" class="my-2">
                                <b-input type="text" placeholder="Last Name" v-model="basicInformation.lastName"></b-input>
                            </b-col>
                            <b-col cols="12" md="3" class="my-2">
                                <b-input type="text" placeholder="Suffix" v-model="basicInformation.suffix"></b-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="6" class="my-2">
                                <b-input type="email" placeholder="Email Address" v-model="basicInformation.email"></b-input>
                            </b-col>
                            <b-col cols="12" md="6" class="my-2">
                                <b-input type="tel" placeholder="Phone Number" v-model="basicInformation.phone"></b-input>
                            </b-col>
                        </b-row>
                        <b-row class="my-2">
                            <b-col>
                                <b-input type="text" placeholder="Address Line 1" v-model="basicInformation.addressLine1"></b-input>
                            </b-col>
                        </b-row>
                        <b-row class="my-2">
                            <b-col>
                                <b-input type="text" placeholder="Address Line 2" v-model="basicInformation.addressLine2"></b-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="4" class="my-2">
                                <b-input type="text" placeholder="City" v-model="basicInformation.city"></b-input>
                            </b-col>
                            <b-col cols="12" md="4" class="my-2">
                                <b-form-select v-model="basicInformation.state" :options="options"></b-form-select>
                            </b-col>
                            <b-col cols="12" md="4" class="my-2">
                                <b-input type="text" placeholder="Zipcode" v-model="basicInformation.zipcode"></b-input>
                            </b-col>
                        </b-row>
                        <b-row class="my-2">
                            <b-col>
                                <div v-for="(child, counter) in basicInformation.children" v-bind:key="counter">
                                    <span class="float-right mt-4" @click="deleteChild(counter)">X</span><br>
                                    <label for="duration">Child {{counter+1}}:</label>
                                    <b-row>
                                        <b-col cols="12" md="3" class="my-2">
                                            <b-input type="text" v-model.lazy="child.firstName" required placeholder="First Name"></b-input>
                                        </b-col>
                                        <b-col cols="12" md="3" class="my-2">
                                            <b-input type="text" v-model.lazy="child.middleName" placeholder="Middle Name"></b-input>
                                        </b-col>
                                        <b-col cols="12" md="3" class="my-2">
                                            <b-input type="text" v-model.lazy="child.lastName" required placeholder="Last Name"></b-input>
                                        </b-col>
                                        <b-col cols="12" md="3" class="my-2">
                                            <b-input type="text" v-model.lazy="child.suffix" placeholder="Suffix"></b-input>
                                        </b-col>
                                    </b-row>
                                    <b-row class="my-2">
                                        <b-col>
                                            <b-form-select v-model.lazy="child.gender" class="mb-3">
                                                <b-form-select-option :value="null">Gender</b-form-select-option>
                                                <b-form-select-option value="Male">Male</b-form-select-option>
                                                <b-form-select-option value="Female">Female</b-form-select-option>
                                            </b-form-select>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <label :for="child.birthday">Birthday:</label>
                                            <b-input type="date" v-model.lazy="child.birthday" required></b-input>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="my-2">
                            <b-col>
                                <b-button @click="addChild"  variant="primary">Add Another Child</b-button>
                            </b-col>
                        </b-row>
                        <b-row class="my-4">
                            <b-col>
                                <b-form-checkbox id="checkbox-1"
                                    v-model="status"
                                    name="checkbox-1"
                                    value="accepted"
                                    unchecked-value="not_accepted"
                                    required>Yes, I consent to the collection and/or usage by SC First Steps or our partners of your personal information as referred to or otherwise stated in the <a target="_blank" href="https://first5sc.org/privacy-policy">Privacy Policy and Terms of Use.</a></b-form-checkbox>
                            </b-col>
                        </b-row>
                        <b-button type="submit"  class="float-right" variant="primary">Next</b-button>
                    </b-form>
                </b-col>
            </b-row>
           
        </div>
    </b-container>
</template>


<script>

import { API, Amplify } from 'aws-amplify';
import * as mutations from '../graphql/mutations.js';
import {
  listUsersByOwner,
  listChildrenByOwner,

} from "../graphql/queries";

export default {
    name: 'Start',
    components: {

    },
    data() {
        return {
            showForm: true,
            status: 'not_accepted',
            userId: '',
            basicInformation: {
                firstName: '',
                middleName: '',
                lastName: '',
                suffix: '',
                email: '',
                phone: '',
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: null,
                zipcode: '',
                county: '',
                schoolDistrict: '',
                children: [
                    {
                        firstName: '',
                        middleName: '',
                        lastName: '',
                        suffix: '',
                        gender: null,
                        birthday: ''
                    }
                ]
            },
            options: [
                    {value: null, text: 'Please select a state'},
                    { value: 'Alabama', text: 'Alabama' },
                    { value: 'Alaska', text: 'Alaska' },
                    { value: 'Arizona', text: 'Arizona'},
                    { value: 'Arkansas', text: 'Arkansas' },
                    { value: 'California', text: 'California' },
                    { value: 'Colorado', text: 'Colorado' },
                    { value: 'Connecticut', text: 'Connecticut' },
                    { value: 'Delaware', text: 'Delaware' },
                    { value: 'Florida', text: 'Florida' },
                    { value: 'Georgia', text: 'Georgia' },
                    { value: 'Hawaii', text: 'Hawaii' },
                    { value: 'Idaho', text: 'Idaho' },
                    { value: 'Illinois', text: 'Illinois' },
                    { value: 'Indiana', text: 'Indiana' },
                    { value: 'Iowa', text: 'Iowa' },
                    { value: 'Kansas', text: 'Kansas' },
                    { value: 'Kentucky', text: 'Kentucky' },
                    { value: 'Louisiana', text: 'Louisiana' },
                    { value: 'Maine', text: 'Maine' },
                    { value: 'Maryland', text: 'Maryland' },
                    { value: 'Massachusetts', text: 'Massachusetts' },
                    { value: 'Michigan', text: 'Michigan' },
                    { value: 'Minnesota', text: 'Minnesota' },
                    { value: 'Mississippi', text: 'Mississippi' },
                    { value: 'Missouri', text: 'Missouri' },
                    { value: 'Montana', text: 'Montana' },
                    { value: 'Nebraska', text: 'Nebraska' },
                    { value: 'Nevada', text: 'Nevada' },
                    { value: 'New Hampshire', text: 'New Hampshire' },
                    { value: 'New Jersey', text: 'New Jersey' },
                    { value: 'New Mexico', text: 'New Mexico' },
                    { value: 'New York', text: 'New York' },
                    { value: 'North Carolina', text: 'North Carolina' },
                    { value: 'North Dakota', text: 'North Dakota' },
                    { value: 'Ohio', text: 'Ohio' },
                    { value: 'Oklahoma', text: 'Oklahoma' },
                    { value: 'Oregon', text: 'Oregon' },
                    { value: 'Pennsylvania', text: 'Pennsylvania' },
                    { value: 'Rhode Island', text: 'Rhode Island' },
                    { value: 'South Carolina', text: 'South Carolina' },
                    { value: 'South Dakota', text: 'South Dakota' },
                    { value: 'Tennessee', text: 'Tennessee' },
                    { value: 'Texas', text: 'Texas' },
                    { value: 'Utah', text: 'Utah' },
                    { value: 'Vermont', text: 'Vermont' },
                    { value: 'Virginia', text: 'Virginia' },
                    { value: 'Washington', text: 'Washington' },
                    { value: 'West Virginia', text: 'West Virginia' },
                    { value: 'Wisconsin', text: 'Wisconsin' },
                    { value: 'Wyoming', text: 'Wyoming' }
                ]
        }
    },
    mounted: function() {
          Amplify.Auth.currentAuthenticatedUser()
            .then(async (data) => {
                //console.log(data);
                this.userId = data.username + '::' + data.username;
                 var userInfos = await API.graphql({
                    query: listUsersByOwner, 
                    variables: {limit: 1000, owner: this.userId},
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                });

                 //console.log('user infos ',userInfos);
                 if (userInfos.data.listUsersByOwner.items.length == 0) {
                    this.loadInfoFromCookies();

                } else {

                    for(var j = 0; j < userInfos.data.listUsersByOwner.items.length; j++) {
                        var u = userInfos.data.listUsersByOwner.items[j];
                        this.basicInformation = {
                        firstName: u.firstName,
                        middleName: u.middleName,
                        lastName: u.lastName,
                        suffix: u.suffix,
                        email: u.email,
                        phone: u.phone,
                        addressLine1: u.addressLine1,
                        addressLine2: u.addressLine2,
                        city: u.city,
                        state: u.state,
                        county: u.county,
                        zipcode: u.zipcode,
                        schoolDistrict: u.schoolDistrict,
                        children: [
                           
                        ]
                    }
                    }

                    if(this.$cookie.get('screenerAddress')){
                         var address = JSON.parse(this.$cookie.get('screenerAddress'));
                         var county = '';
                        try{
                            county = address.administrative_area_level_2.split(' ')[0];
                        }
                        catch{
                            county = '';
                        }

                        this.basicInformation.county = county;
                    }

                    if(this.$cookie.get('screenerDistrict')){
                        var schoolDistrict = this.$cookie.get('screenerDistrict');
                        this.basicInformation.schoolDistrict = schoolDistrict;
                    }

                    var children = await API.graphql({ 
                        query: listChildrenByOwner, 
                        variables: {limit: 1000, owner: this.userId},
                        authMode: "AMAZON_COGNITO_USER_POOLS",
                    });
                    console.log('children ', children);
                    if (children.data.listChildrenByOwner.items.length == 0) {
                        this.basicInformation.children.push( {
                                firstName: '',
                                middleName: '',
                                lastName: '',
                                suffix: '',
                                gender: null,
                                birthday: ''
                            });
                    }
                    for(var k = 0; k < children.data.listChildrenByOwner.items.length; k++) {
                        var child = children.data.listChildrenByOwner.items[k];
                       this.basicInformation.children.push({
                            firstName: child.firstName,
                                middleName: child.middleName,
                                lastName: child.lastName,
                                suffix: child.suffix,
                                gender: child.gender,
                                birthday: child.birthday
                       });
                    }
                }
            }).catch(async (e) => {
                console.log('not logged in', e);
                if (!this.$cookie.get('screenerCompleted'))
                    {
                        //havent completed eligibility screener - send to error page
                        this.$router.push({name: 'EligibilityError'});
                    }
                    else {
                        if(this.$session.get('currentUser')){
                            var currentUser = this.$session.get('currentUser');

                            var information = {
                                firstName: currentUser.firstName,
                                middleName: currentUser.middleName,
                                lastName: currentUser.lastName,
                                suffix: currentUser.suffix,
                                email: currentUser.email,
                                phone: currentUser.phone,
                                addressLine1: currentUser.addressLine1,
                                addressLine2: currentUser.addressLine2,
                                city: currentUser.city,
                                state: currentUser.state,
                                county: currentUser.county,
                                zipcode: currentUser.zipcode,
                                schoolDistrict: currentUser.schoolDistrict,
                                children: [
                                    {
                                        firstName: '',
                                        middleName: '',
                                        lastName: '',
                                        suffix: '',
                                        gender: null,
                                        birthday: ''
                                    }
                                ]
                            }
                            if(this.$session.get('children')){
                                information.children = this.$session.get('children');
                            }

                            this.basicInformation = information;
                            
                        } else {
                            
                        this.loadInfoFromCookies();
                        
                            
                        }
            }
         });
        
    },
    methods: {
        loadInfoFromCookies() {
          

                        var address = JSON.parse(this.$cookie.get('screenerAddress'));
                        var firstName = '';
                        var lastName = '';
                        try {
                            firstName = this.$cookie.get('screenerName').split(' ')[0];
                            lastName = this.$cookie.get('screenerName').split(' ')[1];
                        } catch {
                            lastName = '';
                        }

                        var schoolDistrict = this.$cookie.get('screenerDistrict');

                        var county = '';
                        try{
                            county = address.administrative_area_level_2.split(' ')[0];
                        }
                        catch{
                            county = '';
                        }

                        //new guest user load from eligibility screener
                        this.basicInformation = {
                            firstName: firstName,
                            middleName: '',
                            lastName: lastName,
                            suffix: '',
                            email: this.$cookie.get('screenerEmail'),
                            phone:this.$cookie.get('screenerPhone'),
                            addressLine1: address.street_number + ' ' + address.route,
                            addressLine2: this.$cookie.get('screenerAptNumber'),
                            city: address.locality,
                            //state: address.administrative_area_level_1,
                            state: 'South Carolina',
                            county: county,
                            schoolDistrict: schoolDistrict,
                            zipcode: address.postal_code,
                            children: [
                                {
                                    firstName: '',
                                    middleName: '',
                                    lastName: '',
                                    suffix: '',
                                    gender: null,
                                    birthday: ''
                                }
                            ]
                        }
                    
        },
        addChild() {
            this.basicInformation.children.push(
                {
                    firstName: '',
                    middleName: '',
                    lastName: '',
                    suffix: '',
                    gender: null,
                    birthday: ''
                }
            );
        },
        deleteChild(counter){
            this.basicInformation.children.splice(counter,1);
        },
        basicInfoSubmit(event) {
            event.preventDefault();
            this.showForm = false;
            Amplify.Auth.currentAuthenticatedUser()
            .then(async (result) => {
                if (result) {
                    this.userId = result.username + '::' + result.username;
                   //get current user
                   var currentUser;
                    var userInfos = await API.graphql({
                        query: listUsersByOwner, 
                        variables: {limit: 1000, owner: this.userId},
                        authMode: "AMAZON_COGNITO_USER_POOLS",});
                    if (userInfos.data.listUsersByOwner.items.length > 0) {
                       currentUser = userInfos.data.listUsersByOwner.items[0];
                    }

                     //delete existing child data
                    var children = await API.graphql({ 
                        query: listChildrenByOwner, 
                        variables: {limit: 1000, owner: this.userId},
                        authMode: "AMAZON_COGNITO_USER_POOLS",});
                    for(var k = 0; k < children.data.listChildrenByOwner.items.length; k++) {
                        await API.graphql({ query: mutations.deleteChild, variables: {input: {id: children.data.listChildrenByOwner.items[k].id }}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
                    }


                    //save basic info to user database here
                    if (currentUser) {
                        //update existing user
                         var newUser = {
                            id: currentUser.id,
                            firstName: this.basicInformation.firstName,
                            middleName: this.basicInformation.middleName,
                            lastName: this.basicInformation.lastName,
                            suffix: this.basicInformation.suffix,
                            email: this.basicInformation.email,
                            phone: this.basicInformation.phone,
                            addressLine1: this.basicInformation.addressLine1,
                            addressLine2: this.basicInformation.addressLine2,
                            city: this.basicInformation.city,
                            state: this.basicInformation.state,
                            zipcode: this.basicInformation.zipcode,
                            county: this.basicInformation.county,
                            schoolDistrict: this.basicInformation.schoolDistrict,
                            eligiblePrograms: currentUser.eligiblePrograms
                        };
                        await API.graphql({ query: mutations.updateUser, variables: {input: newUser}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
                    } else {
                        //add new user info row
                        var newUser2 = {
                            
                            firstName: this.basicInformation.firstName,
                            middleName: this.basicInformation.middleName,
                            lastName: this.basicInformation.lastName,
                            suffix: this.basicInformation.suffix,
                            email: this.basicInformation.email,
                            phone: this.basicInformation.phone,
                            addressLine1: this.basicInformation.addressLine1,
                            addressLine2: this.basicInformation.addressLine2,
                            city: this.basicInformation.city,
                            state: this.basicInformation.state,
                            zipcode: this.basicInformation.zipcode,
                            county: this.basicInformation.county,
                            schoolDistrict: this.basicInformation.schoolDistrict,
                           
                        };
                        await API.graphql({ query: mutations.createUser, variables: {input: newUser2}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
                    }

                   
                       
                        
                        for(var i = 0; i < this.basicInformation.children.length; i++){
                            var child = this.basicInformation.children[i];
                            await API.graphql({ query: mutations.createChild, variables: {input: child}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
                           
                        }

                        this.$router.push({name: 'AuthorizedApply'});
                    
                }
                
            })
            .catch((e) => {
                console.log('error ', e);
            this.$router.push({
                name: 'GuestApply',
                params: { data: this.basicInformation }
            });
            });
        }
       
    }
}
</script>