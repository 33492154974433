import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Start from "../views/Start.vue";
import SignUp from "../views/SignUp.vue";
import AuthorizedApply from "../views/AuthorizedApply.vue";
import GuestApply from "../views/GuestApply.vue";
import Documents from "../views/Documents.vue";
import EligibilityError from "../views/EligibilityError.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/start",
    name: "Start",
    component: Start
  },
  {
    path: "/signup",
    name: "SignUp",
    props: true,
    component: SignUp
  },
  {
    //Dynamic route for form slug
    path: "/user/apply/:form?",
    name: "AuthorizedApply",
    props: true,
    component: AuthorizedApply
  },
  {
    //Dynamic route for form slug
    name: "GuestApply",
    path: "/guest/apply/:form?",
    props: true,
    component: GuestApply
  },
  {
    name: "Documents",
    path: "/documents",
    component: Documents
  },
  {
    name: "EligibilityError",
    path: "/EligibilityError",
    component: EligibilityError
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
