<template>
  <b-container class="container">
    <amplify-authenticator username-alias="email">
      <amplify-sign-in slot="sign-in" username-alias="email"> </amplify-sign-in>
      <amplify-sign-up
                        slot="sign-up"
                        header-text="First Five SC - Create an Account"
                        submit-button-text="Register"
                        username-alias="email"
                    >
                        <div slot="header-subtitle">
                          <p>Your password must contain an uppercase letter, lowercase letter, number and symbol (such as *, $, %, etc). </p>
                           <p>We will text a confirmation code to the phone number you enter below.</p>
                        </div>
                    </amplify-sign-up>
      <div class="row form-container">
        <div id="form-div">
          <div ref="programFormsList" v-show="programShow">
            <div class="listing-block">
              <div class="row">
                <div class="col-md-12 text-right app-menu">         
              <ul>
                <li><a href="#" @click="$router.push({ name: 'Start' })">edit basic information</a></li>
                <li><a href="#" @click="$router.push({ name: 'Documents' })">Access Document Vault</a></li>
                <li style="max-width: 200px;"><amplify-sign-out style="margin-top: -10px;"></amplify-sign-out></li>
              </ul>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <h2 class="mb-2">Start a New Application</h2>
                </div>
              </div>
              <div
                class="program-form-box mb-4"
                v-for="program in formsList"
                :key="program.FormId"
              >
                <b-row align-v="center" class="m-4">
                  <b-col md="2" class="text-center text-md-right">
                    <a
                      class="program-form-button button"
                      @click="completeForm(program)"
                      ><img src="@/assets/images/form-new.svg"
                    /></a>
                  </b-col>
                  <b-col
                    md="8"
                    :class="
                      program.Programs ? 'program-desc has-sub' : 'program-desc'
                    "
                  >
                    <h3>
                      <a  @click="completeForm(program)">
                                            {{program.Name}}
                                        </a>
                    </h3>
                    <p>
                      {{ program.ShortDescription }}
                    </p>
                  </b-col>
                </b-row>
                <div v-if="program.Programs">
                  <b-row
                    v-for="(subProgram, index) in program.Programs"
                    :key="index"
                    class="m-4"
                  >
                    <b-col md="7" offset-md="3" class="program-desc">
                      <h3>
                        {{ subProgram.Name }}
                      </h3>
                      <p>
                        {{ subProgram.ShortDescription }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div class="looking-for-more-programs">
                  <h3>Looking for more programs?</h3>
                  <p>Check eligibility for each child.  Check again if you've moved.</p>
                  <a class="button" :href="this.$hostname + '/connect-to-services/'">Complete the eligibility screener again</a>
              </div>
             
            </div>
            <div
              class="my-4 pt-4 listing-block"
              v-if="inprogressForms.length > 0"
            >
              <h2 class="my-4">In Progress Applications</h2>
              <div
                class="program-form-box mb-4"
                v-for="(form, index) in sortedInprogressForms"
                :key="index"
              >
                <b-row align-v="center" class="m-4">
                  <b-col md="2" class="text-center text-md-right">
                    <a
                      class="program-form-button button"
                      @click="editForm(form)"
                      ><img src="@/assets/images/form-edit.svg"
                    /></a>
                  </b-col>
                  <b-col md="8" class="program-desc">
                    <h4>
                      <a @click="editForm(form)">
                                            {{form.formName}} - {{form.childName}}
                                        </a>
                    </h4>
                    <p>
                      Last Updated: {{ moment(form.lastUpdated).fromNow() }}
                    </p>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div
              class="my-4 py-4 listing-block"
              v-if="completedForms.length > 0"
            >
              <h2 class="my-4">Completed Applications</h2>
              <div
                class="program-form-box mb-4"
                v-for="(form, index) in sortedCompletedForms"
                :key="index"
              >
                <b-row align-v="center" class="m-4">
                  <b-col md="2" class="text-center text-md-right">
                    <img src="@/assets/images/form-completed.svg" />
                  </b-col>
                  <b-col md="8" class="program-desc">
                    <h4>{{ form.formName }} - {{ form.childName }}</h4>
                    <p>Submitted: {{ moment(form.submittedDate).fromNow() }}</p>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
          <div id="program-form-wrapper" v-show="!programShow">
            <div v-if="showChildrenSelect == true">
              <h3>{{ selectedFormName }}</h3>
              <p>Which child would you like to apply for?</p>
              <b-form-select v-model="childSelect" @change="buildForm">
                <b-form-select-option :value="null"
                  >Please Select A Child</b-form-select-option
                >
                <b-form-select-option
                  v-for="(child, counter) in children"
                  v-bind:key="counter"
                  :value="counter"
                >
                  {{ child.firstName + " " + child.lastName }}
                </b-form-select-option>
              </b-form-select>
            </div>
            <div v-if="showChildrenCheck == true">
              <h3>{{ selectedFormName }}</h3>
              <p>Which child(ren) would you like to apply for?</p>
              <b-form-checkbox-group
                id="children-checkbox"
                v-model="checkedChildren"
                stacked
                class="mb-3"
              >
                <b-form-checkbox
                  v-for="(child, counter) in children"
                  v-bind:key="counter"
                  :value="counter"
                  >{{ child.firstName + " " + child.lastName }}</b-form-checkbox
                >
              </b-form-checkbox-group>
              <b-button @click="buildFormMultiple()">Next</b-button>
            </div>
            <div
              v-for="i in totalForms"
              v-bind:key="i"
              :id="'form-wrapper-' + i"
            >
              <div :id="'form-' + i"></div>
            </div>
          </div>
        </div>
      </div>
      <b-modal id="bv-modal-confirm" hide-footer centered>
        <template #modal-title> Application Submitted Successfully! </template>
        <div class="d-block" v-html="selectedFormConfirmation">
         
        </div>
        <b-button
          class="mt-3"
          block
          @click="$bvModal.hide('bv-modal-confirm')"
          variant="success"
          >OK</b-button
        >
      </b-modal>
      <b-modal id="inprogress-modal" hide-footer centered>
        <template #modal-title> You have previously started this form </template>
        <div class="d-block">
          <p>
            Our records indicate that you have previously started this form. 
          </p>
          <p>
            Would you like to:
          </p>
        </div>
        <b-button
          class="mt-3"
          block
          @click="modalCompleteForm(modalSelectedForm)"
          variant="success"
          >Start Again</b-button
        >
        <b-button
          class="mt-3"
          block
          @click="modalEditForm(modalInProgressForm)"
          variant="success"
          >Complete Form</b-button
        >
      </b-modal>
    </amplify-authenticator>
  </b-container>
</template>
<script>
import Vue from "vue";
import FileUpload from "../components/FileUpload.vue";
import FS4KProviderPicker from "../components/FS4KProviderPicker.vue";
import { Amplify, API, Hub } from "aws-amplify";
import {
  listUsersByOwner,
  listChildrenByOwner,
  listInProgressFormByOwner,
  listCompletedFormByOwner,
} from "../graphql/queries";
import * as mutations from "../graphql/mutations.js";
import * as prefills from "../prefills.js";

export default {
  name: "Apply",
  components: {},
  data() {
    return {
      api: null,
      currentUser: null,
      bInfo: "",
      bEditHash: "",
      programShow: true,
      showChildrenSelect: false,
      showChildrenCheck: false,
      childSelect: null,
      selectedFormName: "",
      selectedFormId: 0,
      selectedFormConfirmation: "",
      children: [],
      totalForms: 1,
      checkedChildren: [],
      formsList: [],
   
      completedForms: [],
      inprogressForms: [],

      modalSelectedForm: null,
      modalInProgressForm: null,
      userId: ''
    };
  },
  mounted: function () {
    console.log('mounted');
    this.api = window.Cognito("FCB5w58V7kC4cM_iSlvkGQ");

    //Dynamic route for matching a predefined form slug
    if (this.$route.params.form) {
      var selectedForm = this.formsList.find(
        (x) => x.Slug == this.$route.params.form
      );
      this.selectedFormId = selectedForm.FormId;
      this.programShow = false;
      if (this.$route.hash) {
        this.showChildrenCheck = false;
              this.showChildrenSelect = false;
        this.api
          .mount(this.selectedFormId, "#form-" + this.totalForms)
          .overrideText("save-button-text", "Save and Continue Later");
        this.totalForms++;
      } else {
        if (selectedForm.SelectMultiple) {
          this.showChildrenCheck = true;
          this.showChildrenSelect = false;
        } else {
          this.showChildrenSelect = true;
          this.showChildrenCheck = false;
        }
        this.selectedFormId = selectedForm.FormId;
        this.selectedFormName = selectedForm.Name;
        this.selectedFormConfirmation = selectedForm.SubmissionText;
        this.$forceUpdate();
      }
    }
  },
  created() {
    console.log('created');
    Amplify.Auth.currentAuthenticatedUser()
      .then((result) => {
        if (result) {
          //console.log('user authenticated ', result);
          this.userId = result.username + '::' + result.username;
          API.graphql({
            query: listUsersByOwner, 
            variables: {limit: 1000, owner: this.userId},
            authMode: "AMAZON_COGNITO_USER_POOLS",
          }).then((users) => {
            this.currentUser = users.data.listUsersByOwner.items[0];
            if (this.currentUser) {
              this.loadInfoFromCookies();
            } else {
              console.log('no user found in database');
            }
          });
          API.graphql({
            query: listChildrenByOwner, 
            variables: {limit: 1000, owner: this.userId},
            authMode: "AMAZON_COGNITO_USER_POOLS",
          }).then((children) => {
            this.children = children.data.listChildrenByOwner.items;
            //if you are logged in but dont have any children - send back to basic info screen
            if (this.children.length == 0) {
              console.log("no children found - redirecting to basic info");
              this.$router.push({ name: "Start" });
            }
          });
          //console.log('userid=',this.userId);
          API.graphql({
            query: listCompletedFormByOwner, 
            variables: {limit: 1000, owner: this.userId},
            authMode: "AMAZON_COGNITO_USER_POOLS",
          }).then((forms) => {
            this.completedForms = forms.data.listCompletedFormByOwner.items;
          });
          API.graphql({
            query: listInProgressFormByOwner, 
            variables: {limit: 1000, owner: this.userId},
            authMode: "AMAZON_COGNITO_USER_POOLS",
          }).then((forms) => {
            this.inprogressForms = forms.data.listInProgressFormByOwner.items;
          });
        }
      })
      .catch((err) => console.log("no user, displaying login box", err));

    Hub.listen("auth", async (data) => {
      switch (data.payload.event) {
        case "signOut":
          this.$router.push({ name: "Home" });
          break;
        case "signIn":

          this.$gtag.event('login');
   
          this.userId = data.payload.data.username + '::' + data.payload.data.username;
          console.log('userid', this.userId);
          //if user and children data update user data
          //TODO - remove session after added to user

          if (this.$session.get("currentUser")) {
            this.currentUser = this.$session.get("currentUser");

            //delete existing user info
            var userInfos = await API.graphql({
              query: listUsersByOwner, 
              variables: {limit: 1000, owner: this.userId},
              authMode: "AMAZON_COGNITO_USER_POOLS",
            });
            for (var m = 0; m < userInfos.data.listUsersByOwner.items.length; m++) {
              await API.graphql({
                query: mutations.deleteUser,
                variables: {
                  input: { id: userInfos.data.listUsersByOwner.items[m].id },
                },
                authMode: "AMAZON_COGNITO_USER_POOLS",
              });
            }

            //add user info
            await API.graphql({
              query: mutations.createUser,
              variables: { input: this.currentUser },
              authMode: "AMAZON_COGNITO_USER_POOLS",
            });
            this.$session.remove("currentUser");
          }

          if (this.$session.get("children")) {
            this.children = this.$session.get("children");
            if (this.children.length > 0) {
              //delete existing children
              var children = await API.graphql({
                query: listChildrenByOwner,
                variables: {limit: 1000, owner: this.userId},
                authMode: "AMAZON_COGNITO_USER_POOLS",
              });
              for (
                var l = 0;
                l < children.data.listChildrenByOwner.items.length;
                l++
              ) {
                await API.graphql({
                  query: mutations.deleteChild,
                  variables: {
                    input: { id: children.data.listChildrenByOwner.items[l].id },
                  },
                  authMode: "AMAZON_COGNITO_USER_POOLS",
                });
              }

              //add children from session
              for (var i = 0; i < this.children.length; i++) {
                var child = this.children[i];
                await API.graphql({
                  query: mutations.createChild,
                  variables: { input: child },
                  authMode: "AMAZON_COGNITO_USER_POOLS",
                });
              }
              this.$session.remove("children");
            } else {
              this.$router.push({ name: "Start" });
            }
          }

          console.log("importing session forms to user - authorized apply");
          //if inprogress and completed add to user

          if (this.$session.get("completedForms")) {
            this.completedForms = this.$session.get("completedForms");
            for (var k = 0; k < this.completedForms.length; k++) {
              await API.graphql({
                query: mutations.createCompletedForm,
                variables: { input: this.completedForms[k] },
                authMode: "AMAZON_COGNITO_USER_POOLS",
              });
            }
            this.$session.remove("completedForms");
          }
          if (this.$session.get("inprogressForms")) {
            this.inprogressForms = this.$session.get("inprogressForms");
            for (var j = 0; j < this.inprogressForms.length; j++) {
              await API.graphql({
                query: mutations.createInProgressForm,
                variables: { input: this.inprogressForms[j] },
                authMode: "AMAZON_COGNITO_USER_POOLS",
              });
            }
            this.$session.remove("inprogressForms");
          }

          API.graphql({
            query: listUsersByOwner,
            variables: {limit: 1000, owner: this.userId},
            authMode: "AMAZON_COGNITO_USER_POOLS",
          }).then((users) => {
            this.currentUser = users.data.listUsersByOwner.items[0];
            if (this.currentUser) {
              this.loadInfoFromCookies();
            }
          });
          API.graphql({
            query: listChildrenByOwner,
            variables: {limit: 1000, owner: this.userId},
            authMode: "AMAZON_COGNITO_USER_POOLS",
          }).then((children) => {
            this.children = children.data.listChildrenByOwner.items;
            //if you are logged in but dont have any children - send back to basic info screen
            if (this.children.length == 0) {
              this.$router.push({ name: "Start" });
            }
          });
          API.graphql({
            query: listCompletedFormByOwner, 
            variables: {limit: 1000, owner: this.userId},
            authMode: "AMAZON_COGNITO_USER_POOLS",
          }).then((forms) => {
            this.completedForms = forms.data.listCompletedFormByOwner.items;
          });
          API.graphql({
            query: listInProgressFormByOwner, 
            variables: {limit: 1000, owner: this.userId},
            authMode: "AMAZON_COGNITO_USER_POOLS",
          }).then((forms) => {
            this.inprogressForms = forms.data.listInProgressFormByOwner.items;
          });
          break;
      }
    });

    this.$watch(
      () => this.$route.params,
      (toParams, fromParams) => {
        if (toParams.form && toParams.form != fromParams.form) {
          var selectedForm = this.formsList.find(
            (x) => x.Slug == toParams.form
          );
          this.programShow = false;
          this.selectedFormId = selectedForm.FormId;
          this.selectedFormName = selectedForm.Name;
          this.selectedFormConfirmation = selectedForm.SubmissionText;
          if (this.$route.hash) {
            this.showChildrenCheck = false;
              this.showChildrenSelect = false;
            var inprogForm = this.api.mount(
              this.selectedFormId,
              "#form-" + this.totalForms
            ).overrideText('save-button-text','Save and Continue Later');
            this.totalForms++;
            var that = this;
            var inprogressForm = this.inprogressForms.find(
              (x) => "#" + x.formHash == this.$route.hash
            );
            var lastSavedPage = 0;
            inprogForm.on("ready", function (e) {
              lastSavedPage = e.data.entry.Entry.LastPageViewed;

              that.replaceUploadFields();
            });
            inprogForm.on("afterNavigate", function () {
              setTimeout(that.replaceUploadFields(), 100);
            });
            inprogForm.on("afterSubmit", function (e) {
              var childFormData = e.data;
              that.editFormSubmit(childFormData, inprogressForm);
     
                that.$gtag.event('form_submitted', {
                  'form_name': that.selectedFormName
                });
              
            });
            inprogForm.on("afterSave", function (e) {
              var childFormData = e.data;
              if (lastSavedPage == childFormData.entry.Entry.LastPageViewed) {
                that.editFormSave(childFormData, inprogressForm);
              } else {
                lastSavedPage = childFormData.entry.Entry.LastPageViewed;
              }
              
                that.$gtag.event('form_saved', {
                  'form_name': that.selectedFormName
                });
              
            });
            inprogForm.on("beforeNavigate", function(e){
              if(window.first5uploadInProgress) {
                e.preventDefault();
                return false;
              }
              return true;
            });
          } else {
            if (selectedForm.SelectMultiple) {
              this.showChildrenCheck = true;
              this.showChildrenSelect = false;
            } else {
              this.showChildrenSelect = true;
              this.showChildrenCheck = false;
            }
          }
          this.$forceUpdate();
        } else {
          console.log('back to list');
          try {
            document.getElementById(
                "form-wrapper-" + (this.totalForms - 1)
            ).style.display = "none";
          } catch {
              console.log('couldnt hide form');
          }
          this.programShow = true;
        }
      }
    );
  },
  computed: {
    sortedInprogressForms() {
      let tempForms = this.inprogressForms;
      tempForms = tempForms.sort((a,b) => new Date(b.lastUpdated) - new Date(a.lastUpdated));
      return tempForms;
    },
    sortedCompletedForms() {
      let tempForms = this.completedForms;
      tempForms = tempForms.sort((a,b) => new Date(b.submittedDate) - new Date(a.submittedDate));
      return tempForms;
    },
  },
  methods: {
    async loadInfoFromCookies() {
      console.log('load info from cookies');
      if (this.$cookie.get("screenerPrograms")) {
        console.log('screener cookie found.');
        var countyChanged = false;
        var screenerPrograms = decodeURI(this.$cookie.get("screenerPrograms"));
        console.log(this.currentUser);
        if (this.$cookie.get("screenerCountyChanged"))
        {
          countyChanged = true;
          this.$cookie.remove("screenerCountyChanged");
          console.log('county changed - clear old programs');
        }

        if(this.$cookie.get('screenerAddress'))
        {
          var newAddress = JSON.parse(this.$cookie.get('screenerAddress'));
          var newCounty = newAddress.administrative_area_level_2.split(' ')[0];
          if (newCounty != this.currentUser.county)
          {
            countyChanged = true;
            this.currentUser.county = newCounty;
            console.log('county changed (2) - clear old programs');
          }


        }

        if (this.currentUser.eligiblePrograms != null && !countyChanged) {
          if (screenerPrograms != this.currentUser.eligiblePrograms) {
            var existingPrograms = screenerPrograms.split("|");
            var currentPrograms = this.currentUser.eligiblePrograms.split("|");
            var newPrograms = existingPrograms
              .concat(
                currentPrograms.filter(
                  (item) => existingPrograms.indexOf(item) < 0
                )
              )
              .join("|");

            this.currentUser.eligiblePrograms = newPrograms;
            var updatedUser = {
              id: this.currentUser.id,
              firstName: this.currentUser.firstName,
              middleName: this.currentUser.middleName,
              lastName: this.currentUser.lastName,
              suffix: this.currentUser.suffix,
              email: this.currentUser.email,
              phone: this.currentUser.phone,
              addressLine1: this.currentUser.addressLine1,
              addressLine2: this.currentUser.addressLine2,
              city: this.currentUser.city,
              state: this.currentUser.state,
              county: this.currentUser.county,
              zipcode: this.currentUser.zipcode,
              schoolDistrict: this.currentUser.schoolDistrict,
              eligiblePrograms: screenerPrograms
            };

            await API.graphql({
              query: mutations.updateUser,
              variables: { input: updatedUser },
              authMode: "AMAZON_COGNITO_USER_POOLS",
            });
          }
        } else {
          this.currentUser.eligiblePrograms = screenerPrograms;

          var updatedUser2 = {
            id: this.currentUser.id,
            firstName: this.currentUser.firstName,
            middleName: this.currentUser.middleName,
            lastName: this.currentUser.lastName,
            suffix: this.currentUser.suffix,
            email: this.currentUser.email,
            phone: this.currentUser.phone,
            addressLine1: this.currentUser.addressLine1,
            addressLine2: this.currentUser.addressLine2,
            city: this.currentUser.city,
            state: this.currentUser.state,
            county: this.currentUser.county,
            zipcode: this.currentUser.zipcode,
            schoolDistrict: this.currentUser.schoolDistrict,
            eligiblePrograms: screenerPrograms
          };
          await API.graphql({
            query: mutations.updateUser,
            variables: { input: updatedUser2 },
            authMode: "AMAZON_COGNITO_USER_POOLS",
          });
        }
      } else {
        if (!this.currentUser.eligiblePrograms) {
          this.$router.push({ name: "EligibilityError" });
          return;
        }
      }
      
      fetch(
        this.$hostname + "/umbraco/api/commonapp/getallapplications"
      )
        .then((response) => response.json())
        .then((data) => {
          var allforms = data;
          var eligibleforms = [];
          for (let i = 0; i < allforms.length; i++) {
            //rename First Steps Local Partnership Form
            if(allforms[i].FormId == 13 && this.currentUser.county) 
            {
              allforms[i].Name = this.currentUser.county + ' County First Steps';
            }

            if (allforms[i].ProgramId > 0) {
              if (this.currentUser.eligiblePrograms.includes(allforms[i].ProgramId.toString())) {
                eligibleforms.push(allforms[i]);
              }
            } else {
              var sharedForm = { ...allforms[i] };
              sharedForm.Programs = [];
              console.log(sharedForm, allforms[i]);
              for (let j = 0; j < allforms[i].Programs.length; j++) {
                if (
                  this.currentUser.eligiblePrograms.includes(
                    allforms[i].Programs[j].ProgramId.toString()
                  )
                ) {
                  sharedForm.Programs.push(allforms[i].Programs[j]);
                }
              }
              if (sharedForm.Programs.length > 0) {
                eligibleforms.push(sharedForm);
              }
            }
          }
          this.formsList = eligibleforms;
          this.$forceUpdate();
        });
    },
    completeForm(program) {
      var inprogForm = this.inprogressForms.find(
        (x) => x.formId == program.FormId
      );
      if(inprogForm){
        this.modalSelectedForm = program;
        this.modalInProgressForm = inprogForm;
        this.$bvModal.show('inprogress-modal');
      }
      else{
        this.$router.push({
          name: "AuthorizedApply",
          params: { form: program.Slug },
        });
      }
      
    },
    modalCompleteForm(program){
      this.$bvModal.hide('inprogress-modal');
      this.$router.push({
        name: "AuthorizedApply",
        params: { form: program.Slug },
      });
    },
    buildForm: function () {
      var child = this.children[this.childSelect];
      var prefillFields = null;

      //send gtag event for form being started
      var currentForm = this.formsList.find((x) => x.FormId == this.selectedFormId);
      if(currentForm){
        this.$gtag.event('form_started', {
          'form_name': currentForm.Name
        });
      }

      if (this.selectedFormId == "6") {
        prefillFields = prefills.BabyNet(child, this.currentUser);
      } else if (this.selectedFormId == "9") {
        prefillFields = prefills.FirstSteps4K2223(child, this.currentUser);
      } else if (this.selectedFormId == "12") {
        prefillFields = prefills.CERDEP(child, this.currentUser);

      }
      else if(this.selectedFormId == "14") {
        prefillFields = prefills.FirstSteps4K2324(child, this.currentUser);
      }
      else if(this.selectedFormId == "17") {
        prefillFields = prefills.FirstSteps4K2425(child, this.currentUser);
      }
      else if(this.selectedFormId == "18"){
        prefillFields = prefills.WilliamsburgSD(child, this.currentUser);
      }
      else if(this.selectedFormId == "19"){
        prefillFields = prefills.AllendaleSD(child, this.currentUser);
      }
      else if(this.selectedFormId == "20"){
        var subprograms = "";
        if(currentForm){
          if(currentForm.Programs){
            subprograms = currentForm.Programs.map((x) => x.Name).toString();
          }
        }
        prefillFields = prefills.BeaufortCountySD(child, this.currentUser, subprograms);
      }
      else if(this.selectedFormId == "21"){
        prefillFields = prefills.YorkSD(child, this.currentUser);
      }
      else if(this.selectedFormId == "23"){
        prefillFields = prefills.BeaufortJasperPAT(child, this.currentUser);
      }
      else if(this.selectedFormId == "24" || this.selectedFormId == "25" || this.selectedFormId == "26"){
        prefillFields = prefills.CarolinaHealth(child, this.currentUser);
      }
      else if(this.selectedFormId == "27"){
        prefillFields = prefills.EauClairePAT(child, this.currentUser);
      }
      else if(this.selectedFormId == "28"){
        prefillFields = prefills.LowCountryHFA(child, this.currentUser);
      }
      else if(this.selectedFormId == "29"){
        prefillFields = prefills.McLeodNFP(child, this.currentUser);
      }
      else if(this.selectedFormId == "30"){
        prefillFields = prefills.ParentingPlaceHFA(child, this.currentUser);
      }
      else if(this.selectedFormId == "32"){
        prefillFields = prefills.PrismaPAT(child, this.currentUser);
      }
      this.showChildrenSelect = false;
      this.childSelect = "null";
      var childForm = this.api
        .mount(this.selectedFormId, "#form-" + this.totalForms)
        .prefill(prefillFields).overrideText('save-button-text','Save and Continue Later');
      this.totalForms++;
      var that = this;
      childForm.on("afterSubmit", function (e) {
        var childFormData = e.data;
        that.newFormSubmit(childFormData, child.firstName);
        if(currentForm){
          that.$gtag.event('form_submitted', {
            'form_name': currentForm.Name
          });
        }

      });
      childForm.on("afterSave", function (e) {
        var childFormData = e.data;
        that.newFormSave(childFormData, child.firstName);
        if(currentForm){
          that.$gtag.event('form_saved', {
            'form_name': currentForm.Name
          });
        }
      });
      childForm.on("ready", function () {
        that.replaceUploadFields();
      });
      childForm.on("afterNavigate", function () {
        setTimeout(that.replaceUploadFields(), 100);
      });
      childForm.on("beforeNavigate", function(e){
        if(window.first5uploadInProgress) {
          e.preventDefault();
          return false;
        }
        return true;
      });
    },
    buildFormMultiple() {
      if (this.checkedChildren) {
        this.showChildrenCheck = false;
        var prefillFields = null;
        var currentForm = this.formsList.find((x) => x.FormId == this.selectedFormId);
        if (this.selectedFormId == "8") {
          prefillFields = prefills.Voucher(this.checkedChildren, this.currentUser, this.children);
        } else if (this.selectedFormId == "7") {
          prefillFields = prefills.WIC(this.checkedChildren, this.currentUser, this.children);
        } else if (
          this.selectedFormId == "10" ||
          this.selectedFormId == "11"
        ) {
          var subprograms = "";
          if(currentForm){
            if(currentForm.Programs){
              subprograms = currentForm.Programs.map((x) => x.Name).toString();
              console.log(subprograms);
            }
          }
          
          prefillFields = prefills.FSPartnerships(this.checkedChildren, this.currentUser, this.children, subprograms);
        }
        else if(this.selectedFormId == "31" ||
          this.selectedFormId == "13"){
          var subprogramsUpdated = "";
          if(currentForm){
            if(currentForm.Programs){
              subprogramsUpdated = currentForm.Programs.map((x) => x.Name).toString();
              console.log(subprogramsUpdated);
            }
          }

          prefillFields = prefills.FSPartnershipsUpdated(this.checkedChildren, this.currentUser, this.children, subprogramsUpdated);
        }

        if(currentForm){
          this.$gtag.event('form_started', {
            'form_name': currentForm.Name
          });
        }

        var childForm = this.api
          .mount(this.selectedFormId, "#form-" + this.totalForms)
          .prefill(prefillFields).overrideText('save-button-text','Save and Continue Later');
        this.totalForms++;
        var that = this;

        var childrenNames = "";
        for (var i = 0; i < this.checkedChildren.length; i++) {
          var selectedChild = this.children[this.checkedChildren[i]];
          childrenNames += selectedChild.firstName + ", ";
        }
        childrenNames = childrenNames.slice(0, -2);

        childForm.on("afterSubmit", function (e) {
          var childFormData = e.data;
          that.newFormSubmit(childFormData, childrenNames);
          if(currentForm){
            that.$gtag.event('form_submitted', {
              'form_name': currentForm.Name
            });
          }
        });

        childForm.on("afterSave", function (e) {
          var childFormData = e.data;
          that.newFormSave(childFormData, childrenNames);
          if(currentForm){
            that.$gtag.event('form_saved', {
              'form_name': currentForm.Name
            });
          }
        });
        childForm.on("ready", function () {
          that.replaceUploadFields();
        });
        childForm.on("afterNavigate", function () {
          setTimeout(that.replaceUploadFields(), 100);
        });
        childForm.on("beforeNavigate", function(e){
          if(window.first5uploadInProgress) {
            e.preventDefault();
            return false;
          }
          return true;
         });
        this.checkedChildren = [];
      }
    },
    editForm(form) {
      var selectedForm = this.formsList.find((x) => x.FormId == form.formId);
      this.$router.push({
        name: "AuthorizedApply",
        hash: "#" + form.formHash,
        params: { form: selectedForm.Slug },
      });
    },
    modalEditForm(form){
      this.$bvModal.hide('inprogress-modal');
      var selectedForm = this.formsList.find((x) => x.FormId == form.formId);
      this.$router.push({
        name: "AuthorizedApply",
        hash: "#" + form.formHash,
        params: { form: selectedForm.Slug },
      });
    },

    newFormSubmit(data, childName) {
      document.getElementById(
        "form-wrapper-" + (this.totalForms - 1)
      ).style.display = "none";
      this.$forceUpdate();
      var completedForm = {
        formId: parseInt(data.entry.Form.Id),
        formHash: data.entry.Id,
        formName: data.entry.Form.Name,
        childName: childName,
        submittedDate: data.entry.Entry.DateSubmitted,
      };
      API.graphql({
        query: mutations.createCompletedForm,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: { input: completedForm },
      })
        .then(() => {
          API.graphql({
            query: listCompletedFormByOwner, variables: {limit: 1000},
            authMode: "AMAZON_COGNITO_USER_POOLS",
          }).then((forms) => {
            this.completedForms = forms.data.listCompletedFormByOwner.items;
            this.$forceUpdate();
            this.programShow = true;
            this.$router.push({ name: "AuthorizedApply" });
           
            this.$bvModal.show("bv-modal-confirm");
          });
        })
        .catch((err) => console.log("error creating completed form", err));
    },
    newFormSave(data, childName) {
      document.getElementById(
        "form-wrapper-" + (this.totalForms - 1)
      ).style.display = "none";
      this.$forceUpdate();
      var inprogressForm = {
        formId: parseInt(data.entry.Form.Id),
        formHash: data.link.split("#")[1],
        formName: data.entry.Form.Name,
        childName: childName,
        lastUpdated: data.entry.Entry.DateUpdated,
      };
     
      API.graphql({
        query: mutations.createInProgressForm,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: { input: inprogressForm },
      })
        .then(() => {
          API.graphql({
            query: listInProgressFormByOwner, variables: {limit: 1000, owner: this.userId},
            authMode: "AMAZON_COGNITO_USER_POOLS",
          }).then((forms) => {
            this.inprogressForms = forms.data.listInProgressFormByOwner.items;
         
            this.programShow = true;
            this.$router.push({ name: "AuthorizedApply" });
          });
        })
        .catch((err) => console.log("error creating in progress form", err));
    },
    editFormSubmit(data, inprogressForm) {
      document.getElementById(
        "form-wrapper-" + (this.totalForms - 1)
      ).style.display = "none";
      this.$forceUpdate();
      /*
      this.inprogressForms = this.inprogressForms.filter(
        (x) => "#" + x.formHash == inprogressForm.formHash
      );*/

      //delete from in progress forms
      API.graphql({
        query: listInProgressFormByOwner,
        variables: { owner: this.userId},
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).then((forms) => {
        for (var i = 0; i < forms.data.listInProgressFormByOwner.items.length; i++) {
          if (
            forms.data.listInProgressFormByOwner.items[i].formHash ==
            inprogressForm.formHash
          ) {
            console.log(
              "deleting in progress form with hash" +
                forms.data.listInProgressFormByOwner.items[i].formHash
            );
            API.graphql({
              query: mutations.deleteInProgressForm,
              authMode: "AMAZON_COGNITO_USER_POOLS",
              variables: {
                input: {
                  id: forms.data.listInProgressFormByOwner.items[i].id,
                  _version: forms.data.listInProgressFormByOwner.items[i]._version,
                },
              },
            }).then(() => {
              var completedForm = {
                formId: parseInt(data.entry.Form.Id),
                formHash: data.entry.Id,
                formName: data.entry.Form.Name,
                childName: inprogressForm.childName,
                submittedDate: data.entry.Entry.DateSubmitted,
              };

              API.graphql({
                query: mutations.createCompletedForm,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables: { input: completedForm },
              })
                .then(() => {
                  API.graphql({
                    query: listCompletedFormByOwner,
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                  }).then((forms) => {
                    this.completedForms = forms.data.listCompletedFormByOwner.items;
                 
                    this.$forceUpdate();
                    this.programShow = true;
                    this.$router.push({ name: "AuthorizedApply" });
                
                    this.$bvModal.show("bv-modal-confirm");
                  });
                })
                .catch((err) =>
                  console.log("error creating completed form", err)
                );
            });

            forms.data.listInProgressFormByOwner.items.splice(i, 1);
            this.inprogressForms = forms.data.listInProgressFormByOwner.items;

            break;
          }
        }
      });
    },
    editFormSave(data, inprogressForm) {
     
      console.log("inprogress form saved");

      API.graphql({
        query: listInProgressFormByOwner,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: {owner: this.userId}
      }).then((forms) => {
        for (var i = 0; i < forms.data.listInProgressFormByOwner.items.length; i++) {
          if (
            forms.data.listInProgressFormByOwner.items[i].formHash ==
            inprogressForm.formHash
          ) {
            console.log(
              "updating in progress form with hash" +
                forms.data.listInProgressFormByOwner.items[i].formHash
            );
            API.graphql({
              query: mutations.updateInProgressForm,
              authMode: "AMAZON_COGNITO_USER_POOLS",
              variables: {
                input: {
                  id: forms.data.listInProgressFormByOwner.items[i].id,
                  lastUpdated: data.entry.Entry.DateUpdated,
                },
              },
            }).then(() => {
              forms.data.listInProgressFormByOwner.items[i].lastUpdated =
                data.entry.Entry.DateUpdated;
              this.inprogressForms = forms.data.listInProgressFormByOwner.items;
              this.$router.push({ name: "AuthorizedApply" });
            });
            break;
          }
        }
      });

      this.programShow = true;

      document.getElementById(
        "form-wrapper-" + (this.totalForms - 1)
      ).style.display = "none";
      this.$forceUpdate();
    },
    replaceUploadFields() {
      try {
      var formNumber = this.totalForms - 1;
      //replace upload text fields with AWS upload
      var labels = document.querySelectorAll(
        "#form-wrapper-" + formNumber + " .cog-label"
      );
      var uploadNumber = 0;
      for (const label of labels) {
        if (label.innerText.toLowerCase().includes("upload")) {
          uploadNumber++;
         // console.log('label: ',label);
          var FileUploadClass = Vue.extend(FileUpload);

           //hide text box
           var cogField = document.getElementById(label.htmlFor);
         // console.log('cog input: ', cogField);
          cogField.style.display = "none";

          var existingFiles = [];

          if (cogField.value.startsWith('https')) {
            var urls = cogField.value.split(' ');
            for(const url of urls) {
              console.log('url', url);
              var existingURL = new URL(url).pathname;

              existingFiles.push({file: {name: decodeURI(existingURL.substring(existingURL.lastIndexOf('/')+ 1))},
                            location: url,
                          state: 'success'});
            }

          }

          var allowMultiple = false;
          if (label.innerText.toLowerCase().includes("uploadmultiple")) {
            allowMultiple = true;
          }

          var uploader = new FileUploadClass({
            propsData: {
              id: "uploader-" + formNumber + "-" + uploadNumber,
              existingFiles: existingFiles,
              allowMultipleFiles: allowMultiple,
              allowedFileExtensions: ['.JPG', '.PDF', '.TIFF', '.GIF', '.DOCX', '.BMP', '.PNG', '.JPEG'],
            },
          });
          uploader.$mount();

          label.parentNode.append(uploader.$el);

         

          uploader.$on("add-file-success", function (file) {
            //console.log("upload complete - this, file ", this, file);
            var cogField = this.$el
              .closest(".cog-field")
              .querySelector(".cog-input .el-input input");
            cogField.value += ' ' + file.location;
            cogField.value = cogField.value.trim();
            // Create a new 'change' event
            var event = new Event("change");

            // Dispatch it.
            cogField.dispatchEvent(event);
          });

          uploader.$on("delete-file-success", function (e) {
            //console.log("delete complete - this, file ", this, e.file);
            var cogField = this.$el
              .closest(".cog-field")
              .querySelector(".cog-input .el-input input");
              var newUrls = [];
              var urls = cogField.value.split(' ');
              for(const url of urls) {
                //console.log('url | file location', url, e.file.location);
               if (url != e.file.location) {
                  newUrls.push(url);
               }
              }

              cogField.value = newUrls.join(' ');
            // Create a new 'change' event
            var event = new Event("change");
            // Dispatch it.
            cogField.dispatchEvent(event);
          });
        }
        else if (label.innerText.toLowerCase().includes("fs4kproviderpicker")) {
         // uploadNumber++;
          console.log('label: ',label);
          var FS4KProviderPickerClass = Vue.extend(FS4KProviderPicker);

           //hide text box
           var cogField2 = document.getElementById(label.htmlFor);
          console.log('cog input: ', cogField2);
          cogField2.style.display = "none";


          var picker = new FS4KProviderPickerClass({
            propsData: {
          
            },
          });
          picker.$mount();

          label.parentNode.append(picker.$el);

          picker.$on("onSelect", function (value) {
           
            var cogField = this.$el
              .closest(".cog-field")
              .querySelector(".cog-input .el-input input");
            cogField.value = value;
            // Create a new 'change' event
            var event = new Event("change");

            // Dispatch it.
            cogField.dispatchEvent(event);
          });

        }

        label.innerHTML = label.innerHTML.replace(/\[.*\]/, '');
      }
    } catch(e) {
      console.log('error replace upload fields',e);
      setTimeout(this.replaceUploadFields, 100);
    }
    },

    formatDate(date) {
      var d = new Date(date + "  00:00:00"),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
  },
};
</script>
