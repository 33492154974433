<template>
    <div class="vue-bucket-loader" :class="className">
      <ul v-if="files.length > 0" class="vue-bucket-loader__list">
        <slot
          name="list-item"
          v-for="(fileItem, key) in files"
          :item="fileItem"
          :className="listItemClassNames(fileItem)"
        >
          <li :key="key" :class="className">
            {{ fileItem.file.name }} <span v-if="fileItem.state == 'loading'"> <b-spinner variant="primary" label="Spinning"></b-spinner>&nbsp;&nbsp;Uploading...</span>
            <button
              @click.prevent="handleFileDeleted(fileItem)"
              v-if="fileItem.location"
            >
              delete
            </button>
          </li>
        </slot>
      </ul>
  
      <label
        class="vue-bucket-loader__label"
        @drop.prevent.stop="handleFilesDropped($event)"
        @dragover.prevent.stop="$emit('onDragOver')"
        v-if="allowMultipleFiles || files.length == 0"
      >
        <slot
          name="label"
          :files="files"
        ></slot>
        <input
          ref="fileInput"
          :accept="acceptedTypes"
          class="vue-bucket-loader__input"
          type="file"
          :multiple="allowMultipleFiles"
          @change="handleFilesAdded($event.target.files)"
        
        />
      </label>
      <b-dropdown text="Choose File from your vault"  v-if="vaultFiles.length > 0 && (allowMultipleFiles || files.length == 0)">
        <b-dropdown-item v-for="f in vaultFiles" :key="f.key" @click="chooseVault(f.key)">{{f.key}}</b-dropdown-item>
      
      </b-dropdown>
    </div>
  </template>
  
  <script>
  import { Storage, Auth } from 'aws-amplify';
  import { v4 as uuidv4 } from 'uuid';
  export default {
    name: 'FileUpload',
    data: () => ({
      files: [],
      vaultFiles: [],
    }),
    props: {
      allowMultipleFiles: {
        type: Boolean,
        default: false,
      },
     
      beforeUpload: {
        type: Function,
        required: false,
        default: () => true,
      },
      className: [String, Object, Array],
      allowedFileExtensions: {
        type: Array,
        default: () => [],
      },
      allowedMimeTypes: {
        type: Array,
        default: () => [],
      },
      existingFiles: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      acceptedTypes() {
        return [...this.allowedFileExtensions, ...this.allowedMimeTypes].join(',');
      },
    },
    methods: {
      listItemClassNames({ state, location }) {
        const classNames = ['vue-bucket-loader__list-item'];
        if (state === 'loading') {
          classNames.push('vue-bucket-loader__list-item--loading');
        }
        if (state === 'success' && location !== null) {
          classNames.push('vue-bucket-loader__list-item--success');
        }
        if (state === 'error' && location === null) {
          classNames.push('vue-bucket-loader__list-item--error');
        }
        return classNames;
      },
      handleFilesDropped(event) {
        this.$emit('onDrop');
        this.handleFilesAdded(event.dataTransfer.files);
      },
      async handleFilesAdded(fileList) {
        window.first5uploadInProgress = true;
        const files = Object.keys(fileList).map(key => fileList[key]);
        this.$refs.fileInput.value = '';
        this.$emit('add-files-before', { files });
        // eslint-disable-next-line no-restricted-syntax
        for (const file of files) {
          // eslint-disable-next-line no-await-in-loop
          const uploadState = await this.beforeUpload(file);
          const uploadFile = uploadState instanceof File ? uploadState : file;
          if (uploadState
              && !this.files.some(currentFile => currentFile.file.name === uploadFile.name)
          ) {
            const fileItem = {
              file: uploadFile,
              state: 'loading',
              location: null,
              progress: 0,
            };
            this.files.push(fileItem);
            try {
              /* eslint-disable-next-line no-await-in-loop */
              fileItem.location = await this.uploadFile(uploadFile);
              fileItem.state = 'success';
              this.$emit('add-file-success', fileItem);
            } catch (error) {
              fileItem.state = 'error';
              this.$emit('add-file-error', { fileItem, error });
            }
          }
        }
        window.first5uploadInProgress = false;
      },
      async uploadFile(file) {
        var u = await Auth.currentCredentials();
        console.log('user', u);
        var result = await Storage.vault.put(file.name, file, {
            progressCallback: (progress) => {
                console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                file.progress = 100 * progress.loaded / progress.total;
                this.$forceUpdate();

            },
        });
        console.log('upload result', result);
        var guid = uuidv4();
        var copied = await Storage.vault.copy({ key: result.key, level: 'private' }, { key: 'submitted/' + guid + '/' + result.key, level: 'private' })
        console.log('copied', copied);
        var url = await Storage.vault.get(copied.key,{
            download: false,
            expires: 60*60*24*6, //expire in 6 days
        });
        return url;
      },
      /**
       * When a file should be deleted we remove it from s3
       * and the files array
      */
      async handleFileDeleted(file) {
        this.$emit('delete-file-before', { file });
        try {
          // delete the file from s3
          if (file.location !== null) {
            await Storage.vault.remove(file.name);
          }
          // remove the item from the files array
          this.files.splice(
            this.files.findIndex(item => item === file),
            1,
          );
          this.$emit('delete-file-success', { file });
        } catch (error) {
          this.$emit('delete-file-error', { file, error });
        }
      },

      async chooseVault(key) {
        const fileItem = {
              file: { name: key},
              state: 'loading',
              location: null,
              progress: 0,
            };
            this.files.push(fileItem);
            try {
              var guid = uuidv4();
              /* eslint-disable-next-line no-await-in-loop */
              var copied = await Storage.vault.copy({ key: key, level: 'private' }, { key: 'submitted/' + guid + '/' + key, level: 'private' })
              console.log('copied', copied);
              /* eslint-disable-next-line no-await-in-loop */
              var url = await Storage.vault.get(copied.key,{
                  download: false,
                  expires: 60*60*24*6, //expire in 6 days
              });
              fileItem.location = url;
              fileItem.state = 'success';
              this.$emit('add-file-success', fileItem);
            } catch (error) {
              fileItem.state = 'error';
              this.$emit('add-file-error', { fileItem, error });
            }
             
    },},
    mounted() {
      this.files = [
        ...this.existingFiles,
        ...this.files,
      ];
    },
    created() {
      console.log('file upload created');
      Auth.currentAuthenticatedUser()
        .then((u) => {
          console.log('user',u);
      Storage.vault.list('') // for listing ALL files without prefix, pass '' instead
        .then((result) => {
          console.log('files',result);
          this.vaultFiles = result.filter((x) => !x.key.startsWith('submitted/'));
      
      })
        .catch((err) => console.log(err));
        }).catch((err) => console.log(err));
    },
  };
  </script>
  
  
