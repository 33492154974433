/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      middleName
      lastName
      suffix
      email
      phone
      addressLine1
      addressLine2
      city
      state
      zipcode
      county
      schoolDistrict
      eligiblePrograms
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        middleName
        lastName
        suffix
        email
        phone
        addressLine1
        addressLine2
        city
        state
        zipcode
        county
        schoolDistrict
        eligiblePrograms
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsersByOwner = /* GraphQL */ `
  query ListUsersByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        middleName
        lastName
        suffix
        email
        phone
        addressLine1
        addressLine2
        city
        state
        zipcode
        county
        schoolDistrict
        eligiblePrograms
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompletedForm = /* GraphQL */ `
  query GetCompletedForm($id: ID!) {
    getCompletedForm(id: $id) {
      id
      formId
      formHash
      formName
      childName
      submittedDate
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listCompletedForms = /* GraphQL */ `
  query ListCompletedForms(
    $filter: ModelCompletedFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompletedForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        formId
        formHash
        formName
        childName
        submittedDate
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCompletedFormByOwner = /* GraphQL */ `
  query ListCompletedFormByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCompletedFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompletedFormByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        formId
        formHash
        formName
        childName
        submittedDate
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInProgressForm = /* GraphQL */ `
  query GetInProgressForm($id: ID!) {
    getInProgressForm(id: $id) {
      id
      formId
      formHash
      formName
      childName
      lastUpdated
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listInProgressForms = /* GraphQL */ `
  query ListInProgressForms(
    $filter: ModelInProgressFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInProgressForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        formId
        formHash
        formName
        childName
        lastUpdated
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listInProgressFormByOwner = /* GraphQL */ `
  query ListInProgressFormByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInProgressFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInProgressFormByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        formId
        formHash
        formName
        childName
        lastUpdated
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChild = /* GraphQL */ `
  query GetChild($id: ID!) {
    getChild(id: $id) {
      id
      firstName
      middleName
      lastName
      suffix
      gender
      birthday
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listChildren = /* GraphQL */ `
  query ListChildren(
    $filter: ModelChildFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChildren(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        middleName
        lastName
        suffix
        gender
        birthday
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listChildrenByOwner = /* GraphQL */ `
  query ListChildrenByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelChildFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChildrenByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        middleName
        lastName
        suffix
        gender
        birthday
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPrefillItem = /* GraphQL */ `
  query GetPrefillItem($id: ID!) {
    getPrefillItem(id: $id) {
      id
      key
      value
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listPrefillItems = /* GraphQL */ `
  query ListPrefillItems(
    $filter: ModelPrefillItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrefillItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        value
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listPrefillItemByOwner = /* GraphQL */ `
  query ListPrefillItemByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPrefillItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrefillItemByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        value
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
