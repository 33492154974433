<template>
      <div>
        <b-row class="form-container">
          <b-col lg="5" class="my-4">
            <h3>Create a First Five SC account</h3>
            <p>
              If you are just getting started, create a First Five SC account to apply for benefits.
            </p>
            <b-card class="mb-4">
              <p>
                With a First Five SC account you can:
              </p>
              <ul>
                <li>Save your application and finish it later.</li>
                <li>Keep track of programs you are eligible for.</li>
                <li>Securely share uploaded documents with multiple programs.</li>
              </ul>
            </b-card>
            <b-button @click="$router.push({name: 'SignUp'})" variant="primary" block>Create First Five SC Account</b-button>
            <p class="text-center my-3">-OR-</p>
            <b-button @click="$router.push({name: 'Start'})" variant="outline-primary" block>Apply as a guest</b-button>
          </b-col>
          <b-col lg="1" class="border-col"></b-col>
          <b-col lg="1"></b-col>
          <b-col lg="5" class="my-4">
            <h3>Continue your application</h3>
            <p>
              If you already have a First Five SC account, sign in to finish your application.
            </p>
            <img class="home-image mb-4" src="@/assets/images/home-questions-01.svg" />
            <b-button variant="primary mt-4" block @click="$router.push({name: 'AuthorizedApply'})">Sign In</b-button>
          </b-col>
        </b-row>
      </div>
</template>

<script>
import {Amplify } from 'aws-amplify';
export default {
  name: "Home",
  components: {
  },
  created() {
        Amplify.Auth.currentAuthenticatedUser()
          .then((result) => {
              if (result) {
                  this.$router.push({name: 'AuthorizedApply'});
              }
            })
            .catch((err) => {
                console.log("no user", err);
                if (this.$session.get("currentUser")) {
                  this.$router.push({name: 'GuestApply'});
                }
          });
    }
};
</script>
