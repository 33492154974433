<template>
      <div>
        <b-row class="form-container">
          <b-col lg="5" class="my-4">
            <h3>Error</h3>
            <p>
              Please complete the Eligibility screener to see which programs you are eligible for.  If you receive this error again, make sure cookies are enabled in your web browser.
            </p>
          
            <b-button :href="this.$hostname + '/connect-to-services/'" variant="primary" block>
              Complete Eligibility Screener
            </b-button>
          
          </b-col>
          
        </b-row>
      </div>
</template>

<script>
export default {
  name: "EligibilityError",
  components: {
  },
};
</script>
