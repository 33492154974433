<template>
  <div class="container mt-4" id="app">
    <b-row>
      <b-col align-self="end">
        <b-button class="float-right translate-button" v-b-modal.modal-1>Translate</b-button>
      </b-col>
    </b-row>
    <b-row align-h="center" class="main-nav">
      <b-col md="4">
        <img src="@/assets/images/logo-01.svg" />
      </b-col>
      <b-col md="6" align-self="end">
        <h1>Common Application</h1>
      </b-col>
    </b-row>
    <b-row>
      <router-view></router-view>
    </b-row>
    <b-modal id="modal-1" :hide-footer="true">
      <Translator :countries="countries" @on-country-click="hideModal"/>
    </b-modal>
    <p>First Five SC may show information about you or your dependents’ possible eligibility to participate in program(s). The programs will make final decisions based on the information you provide. It is your responsibility to give the correct information to programs so they can determine eligibility.  If there are programs you are interested in applying for that are not on the First Five SC application, please reach out to them directly.</p>
    <p>"The project described was supported by the Preschool Development Grant Birth through Five Initiative (PDG B-5), Grant Number 90TP0080-02-01 from the Office of Child Care, Administration for Children and Families, U.S. Department of Health and Human Services."</p>
    <p>&copy;{{new Date().getFullYear()}} The State of South Carolina</p>
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script>
/*import Application from '@/components/Application.vue';
import Auth from '@/components/Auth.vue';
export default {
  components: {
    Application,
    Auth
  },
};*/
import { Translator } from 'vue-google-translate';
export default{
  data () {
    return {
      countries: [
        {
          code: "en|en",
          title: "English",
        },
       
        {
          code: "en|es",
          title: "Spanish",
        },
      ],
    }
  },
  components: {
    Translator
  },
  methods: {
    hideModal(){
      this.$bvModal.hide('modal-1');
    }
  }
}
</script>
