<template>
      <b-container class="mt-5">
        <div class="form-container">
          <a href="/user/apply" style="float:right">BACK</a>
          <h2 class="mb-2">Document Vault</h2>
      <h5>Add a File</h5>
      <input ref="fileInput" type="file" @change="upload($event.target.files)"/>
      <div v-if="isUploading"> <b-spinner variant="primary" label="Spinning"></b-spinner>&nbsp;&nbsp;Uploading...</div>
      <h5 class="mt-4">Your files:</h5>
      <ul>
        <li v-for="f in files" :key="f.key">
          <a @click="download(f.key)">{{f.key}}</a> -  <button
              @click.prevent="deletef(f.key)">
              delete
            </button>
        </li>
      </ul>
    </div>
    </b-container>
</template>
<script>
import { Storage, Auth } from 'aws-amplify';
export default {
  name: "Documents",

  
  data() {
    return {
      files: [],
      isUploading: false,
    }
  },
  mounted() {
    
  },

  async created() {
    await Auth.currentAuthenticatedUser()
    this.listFiles();
  },

  methods: {
    async upload(files) {
      console.log('upload start');
      this.isUploading = true;
      const file = files[0];
      await Storage.vault.put(file.name, file, {});
      this.$refs.fileInput.value = '';
      this.isUploading = false;
      this.listFiles();
    },
    async listFiles() {
        var result = await Storage.vault.list('')
        this.files = result.filter((x) => !x.key.startsWith('submitted/'));
    },
    async download(key) {
      var url = await Storage.vault.get(key,{
        download: false,
        expires: 600, //expire in 10 minutes
      });
      window.open(url);
    },
    async deletef(key) {
      await Storage.vault.remove(key);
      this.listFiles();
    }
  }
};
</script>