/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      suffix
      email
      phone
      addressLine1
      addressLine2
      city
      state
      zipcode
      county
      schoolDistrict
      eligiblePrograms
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      suffix
      email
      phone
      addressLine1
      addressLine2
      city
      state
      zipcode
      county
      schoolDistrict
      eligiblePrograms
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      suffix
      email
      phone
      addressLine1
      addressLine2
      city
      state
      zipcode
      county
      schoolDistrict
      eligiblePrograms
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createCompletedForm = /* GraphQL */ `
  mutation CreateCompletedForm(
    $input: CreateCompletedFormInput!
    $condition: ModelCompletedFormConditionInput
  ) {
    createCompletedForm(input: $input, condition: $condition) {
      id
      formId
      formHash
      formName
      childName
      submittedDate
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateCompletedForm = /* GraphQL */ `
  mutation UpdateCompletedForm(
    $input: UpdateCompletedFormInput!
    $condition: ModelCompletedFormConditionInput
  ) {
    updateCompletedForm(input: $input, condition: $condition) {
      id
      formId
      formHash
      formName
      childName
      submittedDate
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompletedForm = /* GraphQL */ `
  mutation DeleteCompletedForm(
    $input: DeleteCompletedFormInput!
    $condition: ModelCompletedFormConditionInput
  ) {
    deleteCompletedForm(input: $input, condition: $condition) {
      id
      formId
      formHash
      formName
      childName
      submittedDate
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createInProgressForm = /* GraphQL */ `
  mutation CreateInProgressForm(
    $input: CreateInProgressFormInput!
    $condition: ModelInProgressFormConditionInput
  ) {
    createInProgressForm(input: $input, condition: $condition) {
      id
      formId
      formHash
      formName
      childName
      lastUpdated
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateInProgressForm = /* GraphQL */ `
  mutation UpdateInProgressForm(
    $input: UpdateInProgressFormInput!
    $condition: ModelInProgressFormConditionInput
  ) {
    updateInProgressForm(input: $input, condition: $condition) {
      id
      formId
      formHash
      formName
      childName
      lastUpdated
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteInProgressForm = /* GraphQL */ `
  mutation DeleteInProgressForm(
    $input: DeleteInProgressFormInput!
    $condition: ModelInProgressFormConditionInput
  ) {
    deleteInProgressForm(input: $input, condition: $condition) {
      id
      formId
      formHash
      formName
      childName
      lastUpdated
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createChild = /* GraphQL */ `
  mutation CreateChild(
    $input: CreateChildInput!
    $condition: ModelChildConditionInput
  ) {
    createChild(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      suffix
      gender
      birthday
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateChild = /* GraphQL */ `
  mutation UpdateChild(
    $input: UpdateChildInput!
    $condition: ModelChildConditionInput
  ) {
    updateChild(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      suffix
      gender
      birthday
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteChild = /* GraphQL */ `
  mutation DeleteChild(
    $input: DeleteChildInput!
    $condition: ModelChildConditionInput
  ) {
    deleteChild(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      suffix
      gender
      birthday
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createPrefillItem = /* GraphQL */ `
  mutation CreatePrefillItem(
    $input: CreatePrefillItemInput!
    $condition: ModelPrefillItemConditionInput
  ) {
    createPrefillItem(input: $input, condition: $condition) {
      id
      key
      value
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updatePrefillItem = /* GraphQL */ `
  mutation UpdatePrefillItem(
    $input: UpdatePrefillItemInput!
    $condition: ModelPrefillItemConditionInput
  ) {
    updatePrefillItem(input: $input, condition: $condition) {
      id
      key
      value
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deletePrefillItem = /* GraphQL */ `
  mutation DeletePrefillItem(
    $input: DeletePrefillItemInput!
    $condition: ModelPrefillItemConditionInput
  ) {
    deletePrefillItem(input: $input, condition: $condition) {
      id
      key
      value
      owner
      createdAt
      updatedAt
    }
  }
`;
