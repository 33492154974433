<template>
    <div>
        <b-form-select
      v-model="selected"
      :options="options"
      class="mb-3" @change="handleSelect"
    ></b-form-select>
    </div>
</template>
<script>

export default {
  name: 'FS4KProviderPicker',
  data: () => ({
    selected: "",
    options: [],
  }),
  props: {
  
  },
  computed: {
   
  },
  methods: {
  
    handleSelect() {


        this.$emit('onSelect',this.selected);
        
      },
     
   },
  mounted() {
   
  },
  created() {
    fetch(
        "https://admin.palmettoprek.org/api/facilitiesapi/GetCommonAppProviders"
      ).then((response) => response.json())
        .then((data) => {
          this.options.push({text: 'Please select a provider',value: ''});
          this.options.push({text: 'Unknown',value: 'unknown'});
          for (const provider of data) {
            var displayText = provider.County + ' - ' + provider.ProviderName;
            if (provider.HideCounty) {
              displayText = 'ZZZ - ' + provider.ProviderName;
            }
            this.options.push({text: displayText, value: displayText});
          }
        });

  },
};
</script>